// import material
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import cloneDeep from 'lodash/cloneDeep'
import Typography from '@material-ui/core/Typography';
// import packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
// import custom files
import { ExecutionActions, ProjectActions, TestSuiteActions, ModalActions, TagActions, AccountActions, GeneralActions } from '../../../store/actions';
import { TestCaseMiddleware } from '../../../store/middleware';
import { getStepsData } from '../../../utils/utils'
import {
    checkObject,
    checkArrayLength,
    checkKeyInObject,
    capitalizeString,
    removeIndexFromArray,
    pushInMidArray,
    checkIsArray,
    isObjectEmpty,
} from '../../../utils/utils';
import { getDevices, getSelectedDevice } from '../../../utils/ExecuteScriptModalUtils';
import { RadioBtnGroup } from '../../../components/Button/RadioBtn';
import { DialogTop } from '../../../components/Modal/ModalTop';
import ExternalGrid from './ExternalGrid'
import { ExecuteScriptModalConstant } from './constant';
import { TestCaseUtils, TestScriptUtils } from '../../../utils';
import { styles } from './styles';
import config from '../../../config';
import WSService from '../../../services/WSService';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import AutoSuggestTag from '../../../components/CustomModal/AutoSuggestTag';
import PlatformBrowserSelectionContainer from './PlatformBrowserSelectionContainer';
import ExecuteScriptConfigurationDetails from './ExecuteScriptConfigurationDetails';
import OverviewPanel from './OverviewPanel'; 
import ExecutionErrorDetails from './ExecutionErrorDetails';
import PlatformBrowserForLocalExecution from './PlatformBrowserForLocalExecution';
import PlatformBrowserForSauceExecution from './PlatformBrowserForSauceExecution';
import { track } from '../../../services/Segment';
import { SEGMENT_EVENT } from '../../../common/constants';
import { successAlertBar } from '../../../services/AlertBarService';

import {warningAlertBar} from '../../../services/AlertBarService';

// global variables
const Devices = getDevices();
const { ExecutionModes, PlatformBasedBrowsers } = ExecuteScriptModalConstant;
let { Platform } = ExecuteScriptModalConstant;
const { Browser, MappedOSX, MappedOSXVersion ,newMappedOSX, newMappedOSXVersion } = ExecuteScriptModalConstant;
let BrowserSpecificVersionsArray = [];
let PlatformSpecificVersionsArray = [];

let isWaitingForMsgTypeTwoResponse = false; // prevent ws receiver call until msgType 2 is being sent
class ExecuteScriptModal extends React.Component {
    constructor(props) {
        super(props);
        this.PlatformBasedBrowsers = JSON.parse(JSON.stringify(PlatformBasedBrowsers));
        this.ModalType = checkKeyInObject(checkKeyInObject(props.prefences, 'accounts'), 'executionEngine') || localStorage.getItem('executionEngine');
        this.windowsBrowser = checkKeyInObject(checkKeyInObject(props.prefences, 'accounts'), 'windowsBrowsers') || localStorage.getItem('windowsBrowser');
        this.ws = {};
        this.state = {
            anchorEl: [null, null],
            browserDialog: [false, false],
            browserDropDownData: [Browser, Browser],
            executeBy: 'suite',
            openModal: false,
            platformDialog: [false, false],
            selectedBrowsers: ['Chrome', 'Chrome'],
            selectedExecutionMode: 'Parallel',
            selectedPlatform: ['Linux', 'Linux'],
            selectedTab: [0, 0],
            showSelectedExecutionModeDialog: false,
            tag: '',
            use_remote_driver: false,
            url: '',
            externalGrid: [{
                environmentType: 'remote',
                platform: '',
                platformVersion: '',
                browser: '',
                browserVersion: ''
            }],
            expanded: '',
            localContent: [{
                environmentType: 'Local',
                browser: 'Chrome (headless)',
                platformVersion: '',
                browserVersion: '',
                platform: 'Linux',
                browserEl: null,
                platformEl: null,
            }],
            sauceContent:[{
                environmentType: 'Saucelabs',
                id: "Windows 10_10_chrome_92",
                browser: "chrome",
                browserVersion: "92",
                platform: "Windows 10",
                platformVersion: "10",
                appiumVersion: "",
                deviceName: "",
                deviceOrientation: "",
                label:"Windows 10 . Chrome 92.0",
                idEl: null,
                tunnelID: '',
            }],
            deviceSettings:[{}],
            platformTab: 'Windows',
            browserTab: 'Chrome',
            platformVersion: '',
            browserVersion: '',
            tabState: 'Saucelabs',
            tunnelID: '',
            configurationSetting: 'lastRunConfiguration',
            sauceContentDisable: false,
            localContentDisable: false,
            externalGridDisable: false,
            lastConfigurationDisable: false,
            isFieldEmpty: false,
            isUrlFieldEmpty: false,
            environmentDetails: {},
            platformLocal: 'Linux',
            browserLocal: 'Chrome',
            localBrowserValues: {},
            sauceTunnelIDs: [],
            lastRunConfigHasLength: true,
            sauceIDDisable: false,
            saucePlatformDisable: false,
            sauceBrowserDisable: false,
            localPlatformDisable: false,
            localBrowserDisable: false,
            extPlatform: false,
            extBrowser: false,
            extPlatformVersion: false,
            noConfigFound: false,
            sauceConfigurationNotFound: true,
            localConfigurationNotFound: true,
            duplicateSauceObjects: {},
            duplicateLocalObjects: {},
            localSysDefault: [],
            sauceSysDefault: [],
            showSauceContent: false,
            showLocalContent: false,
            showRemoteContent: false,
            sauceCredentials:{},
            SauceCredentialLoad:false,
            overflowStyle: 'auto',
            saucelabs_capabliites:{},
        };
    }

    /* component life cycle start */
    async componentDidMount() {
        const { selectedPlatform, sauceContent, deviceSettings } = this.state;
        let {  callingFrom, getSauceDetails, toggleSnackBar, testCase, setShowLoaderForGenerate } = this.props
        let lastConfigurationData = JSON.parse(localStorage.getItem('lastRunConfiguration'))
        let sauceCredentials = await getSauceDetails();

        let _sauceCredentialsDetails;
        let _sauceCredentialLoad = false;

        if(sauceCredentials?.payload){
            if(!sauceCredentials?.payload?.message){
                _sauceCredentialsDetails = sauceCredentials.payload;
                _sauceCredentialLoad = true;
            }else{
                _sauceCredentialLoad = true;
                toggleSnackBar('Sauce Details are not present', '', false, 2000);
            }
        }

        let lastGenerateConfiguration = JSON.parse(localStorage.getItem('lastGenerateConfiguration'))
        const envData = await this.props.getEnvironmentType()
        
        // Set first sauce proxy id as a default value
        const data =  envData?.payload?.data?.environments.some(env => env.environmentType?.toLowerCase() === "saucelabs" || env.environmentType?.toLowerCase() === "saucelab_devices") && !isObjectEmpty(_sauceCredentialsDetails) ?  await this.props.getTunnelIDForSauceLabs() : {};
        
        if(!isObjectEmpty(data) && data.error && typeof data.error === 'string') {
            this.props.toggleSnackBar(data.error)
        }
        
        //Clearing last configuration for generation if "sauce" env is present and it doesn't have id which we use for mapping
        if(lastGenerateConfiguration?.length && lastGenerateConfiguration?.some(data => data.environmentType.toLowerCase() === 'saucelabs' && !data.id)){
            localStorage.removeItem("lastGenerateConfiguration");
            lastGenerateConfiguration = null
        }
        //Clearing last configuration for execution if "sauce" env is present and it doesn't have id which we use for mapping
        if(lastConfigurationData?.length && lastConfigurationData?.some(data => data.environmentType.toLowerCase() === 'saucelabs' && !data.id)){
            localStorage.removeItem("lastRunConfiguration");
            lastConfigurationData = null
        }

        if (callingFrom.toLowerCase() === 'generate') {
            setShowLoaderForGenerate({[testCase.testCaseId] : false})
            if (lastGenerateConfiguration) {
                let _externalGrid = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'remote')
                let _localContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
                let _sauceContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices')
                let _deviceSettings = JSON.parse(JSON.stringify(deviceSettings))
                _sauceContent = _sauceContent?.map((val, ind) => {
                    if(val.deviceType && val.environmentType?.toLowerCase() === 'saucelab_devices'){
                        if(val.deviceType === 'real'){
                            _deviceSettings[ind] = {
                                autoAcceptAlerts: val.autoAcceptAlerts,
                                autoGrantPermissions: val.autoGrantPermission,
                                enableAnimations: val.enableAnimations,
                                orientation: val.orientation,
                                options: val.extraCapabilities
                            }
                        }
                        else{
                            _deviceSettings[ind] = {
                                autoGrantPermissions: val.autoGrantPermission,
                                enableAnimations: val.enableAnimations,
                                orientation: val.orientation,
                                options: val.extraCapabilities
                            }
                        }
                        delete val.autoAcceptAlerts
                        delete val.autoGrantPermission
                        delete val.enableAnimations
                        delete val.orientation
                        delete val.extraCapabilities
                    }
                    else {
                        _deviceSettings[ind] = {}
                    }
                    return {
                        ...val,
                        tunnelID: data?.payload?.data ? (data.payload.data.includes(val.tunnelID) ? val.tunnelID : data?.payload?.data[0]) : ''
                    }
                })


                this.setState({
                    externalGrid: _externalGrid,
                    localContent: _localContent,
                    sauceContent: _sauceContent,
                    deviceSettings: _deviceSettings,
                    localContentDisable: _localContent.length ? false : true,
                    sauceContentDisable: _sauceContent.length && _sauceCredentialsDetails?.sauceCredentialId ? false : true,
                    externalGridDisable: _externalGrid.length ? false : true,
                    lastConfigurationDisable: false,
                    configurationSetting: 'lastRunConfiguration',
                    tabState: lastGenerateConfiguration[0].environmentType.toLowerCase() === 'zalenium' ? 'Local' : lastGenerateConfiguration[0].environmentType === "remote" ? 'externalGrid' : lastGenerateConfiguration[0].environmentType.toLowerCase() === 'saucelab_devices' ? 'Saucelabs' : lastGenerateConfiguration[0].environmentType,
                    localConfigurationNotFound: _localContent.length ? true : false,
                    sauceConfigurationNotFound: _sauceContent.length && _sauceCredentialsDetails?.sauceCredentialId ? true : false,
                    sauceCredentials:_sauceCredentialsDetails,
                    SauceCredentialLoad: _sauceCredentialLoad,
                })
            } else {
                this.setState({ lastConfigurationDisable: true, configurationSetting: 'newConfiguration',SauceCredentialLoad: _sauceCredentialLoad, sauceCredentials:_sauceCredentialsDetails })
            }
        } else {
            if (lastConfigurationData)  {
                let _externalGrid = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'remote')
                let _localContent = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
                let _sauceContent = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices')                
                let _deviceSettings = JSON.parse(JSON.stringify(deviceSettings))
                _sauceContent = _sauceContent?.map((val, ind) => {
                    if(val.deviceType && val.environmentType?.toLowerCase() === 'saucelab_devices'){
                        if(val.deviceType === 'real'){
                            _deviceSettings[ind] = {
                                autoAcceptAlerts: val.autoAcceptAlerts,
                                autoGrantPermissions: val.autoGrantPermissions,
                                enableAnimations: val.enableAnimations,
                                orientation: val.orientation,
                                options: val.options
                            }
                        }
                        else{
                            _deviceSettings[ind] = {
                                autoGrantPermissions: val.autoGrantPermission,
                                enableAnimations: val.enableAnimations,
                                orientation: val.orientation,
                                options: val.options
                            }
                        }
                        delete val.autoAcceptAlerts
                        delete val.autoGrantPermissions
                        delete val.enableAnimations
                        delete val.orientation
                        delete val.options
                    }
                    else {
                        _deviceSettings[ind] = {}
                    }
                    
                    return {
                        ...val,
                        tunnelID: data?.payload?.data ? (data.payload.data.includes(val.tunnelID) ? val.tunnelID : data?.payload?.data[0]) : ''
                    }
                })

                this.setState({
                    externalGrid: _externalGrid,
                    localContent: _localContent,
                    sauceContent: _sauceContent,
                    deviceSettings: _deviceSettings,
                    localContentDisable: _localContent.length ? false : true,
                    sauceContentDisable: _sauceContent.length && _sauceCredentialsDetails?.sauceCredentialId ? false : true,
                    externalGridDisable: _externalGrid.length ? false : true,
                    lastConfigurationDisable: false,
                    configurationSetting: 'lastRunConfiguration',
                    tabState:  _sauceContent?.length && _sauceCredentialsDetails?.sauceCredentialId ? 'Saucelabs' : _localContent?.length ? 'Local' : _externalGrid?.length ? 'externalGrid' : 'Saucelabs',
                    localConfigurationNotFound: _localContent.length ? true : false,
                    sauceConfigurationNotFound: _sauceContent.length && _sauceCredentialsDetails?.sauceCredentialId ? true : false,
                    sauceCredentials:_sauceCredentialsDetails,
                    SauceCredentialLoad: _sauceCredentialLoad,
                    
                })
            } else {
                this.setState({ lastConfigurationDisable: true, configurationSetting: 'newConfiguration',SauceCredentialLoad: _sauceCredentialLoad, sauceCredentials:_sauceCredentialsDetails })
            }
        }

        if (this.props.type === 'suite') {
            this.props.getTags();
        }

        this.ws = await WSService.getWebSocketInstance();
        if (!isWaitingForMsgTypeTwoResponse) {
            this.reviceWSEvents();
        }
       // const envData = await this.props.getEnvironmentType()
        // // Set first sauce proxy id as a default value
        // const data = await this.props.getTunnelIDForSauceLabs();
        if (data && data?.payload?.data && ((callingFrom.toLowerCase() === 'execute' && !lastConfigurationData)  || (callingFrom.toLowerCase() === 'generate' && !lastGenerateConfiguration))) {
            const _sauceContent = cloneDeep(sauceContent);
            this.setState({ sauceContent: [{ ..._sauceContent[0], tunnelID: data?.payload?.data[0] || '' }] });

        }
        if (envData && envData.payload.data && ((callingFrom.toLowerCase() === 'execute' && !lastConfigurationData)  || (callingFrom.toLowerCase() === 'generate' && !lastGenerateConfiguration))) {
            let saucelab = [];
            let saucelabsDevices = [];
            if(envData?.payload?.data?.environments?.some(env => env.environmentType?.toLowerCase() === "saucelabs")){
                saucelab = envData?.payload?.data?.environments?.find(env => env.environmentType?.toLowerCase() === "saucelabs").environment.platformDetails?.map(elem => {
                    return{
                       ...elem,
                       environmentType: "Saucelabs"
                    }
                })
            }
            if(envData?.payload?.data?.environments?.some(env => env.environmentType?.toLowerCase() === "saucelab_devices")){
                saucelabsDevices = envData?.payload?.data?.environments?.find(env => env.environmentType?.toLowerCase() === "saucelab_devices").environment.platformDetails?.map(elem => {
                    return{
                       ...elem,
                       environmentType: "saucelab_devices"
                    }
                })
            }
            if((saucelab.length || saucelabsDevices.length) && _sauceCredentialsDetails?.sauceCredentialId){
                this.constructDataForSauceMapping([...saucelab, ...saucelabsDevices])
            }
            
            this.setUserDefaultData(envData.payload.data, _sauceCredentialsDetails)
        }


        if (this.ModalType === config.executionEngine) {

            let anyWindowBrowser = false;
            (this.windowsBrowser.split(',') || []).forEach((brow) => {
                if (brow) {
                    anyWindowBrowser = true;
                }
                if (brow !== 'ie') {
                    this.PlatformBasedBrowsers.Windows.push(capitalizeString(brow.trim()));
                }
            });
            if (checkArrayLength(Platform)) {
                Platform = Platform.filter((pl) => pl === 'Linux' || (anyWindowBrowser && pl === 'Windows')).sort();
                const browserDropDownData = this.makeStateCopy('browserDropDownData');
                for (let key = 0; key < selectedPlatform.length; key++) {
                    browserDropDownData[key] = this.PlatformBasedBrowsers[selectedPlatform[key]];
                }
                this.updateState({ browserDropDownData });
            }
        } else {
            const browserDropDownData = this.makeStateCopy('browserDropDownData');
            const _selectedPlatform = this.makeStateCopy('selectedPlatform');
            const selectedBrowsers = this.makeStateCopy('selectedBrowsers');
            Platform = checkObject(Devices) ? Object.keys(Devices) : Platform;
            console.log("Platform", Platform);
            PlatformSpecificVersionsArray = Platform.map((pl) => Object.keys(Devices[pl]));
            console.log("PlatformSpecificVersionsArray", PlatformSpecificVersionsArray);
            const mapForBrowser = `${Platform[0]}.${PlatformSpecificVersionsArray[0][0]}`;
            console.log("mapForBrowser", mapForBrowser);
            const browserDetails = checkKeyInObject(Devices, mapForBrowser, 'value', {});
            console.log("browserDetails", browserDetails);
            for (let key = 0; key < _selectedPlatform.length; key++) {
                _selectedPlatform[key] = PlatformSpecificVersionsArray[0][0];
                //console.log("browserDetails",browserDetails);
                browserDropDownData[key] = Object.keys(browserDetails);
                BrowserSpecificVersionsArray = Object.values(browserDetails);
                selectedBrowsers[key] = [`${browserDropDownData[key][0]} ${BrowserSpecificVersionsArray[0][0]}`];
            }
            this.updateState({
                browserDropDownData,
                selectedPlatform: _selectedPlatform,
                selectedBrowsers,
            });
        }
    }

    /* component life cycle end */
    
    constructDataForSauceMapping = (env) =>{
        const platforms = []
        const capabilities_data_map = []

        env.forEach(details => {
            if(details.platform){
                platforms.push(newMappedOSX[details.platform])
            }
        })

        Array.from(new Set(platforms)).forEach( platform => {
            capabilities_data_map.push({
                deviceName: platform,
                deviceLogo: platform?.toLowerCase(),
                deviceVersions: env.map( deviceVer => {
                 if(newMappedOSX[deviceVer.platform] === platform && deviceVer.environmentType?.toLowerCase() === "saucelabs"){
                        return{
                            name: newMappedOSXVersion[deviceVer.platformVersion],
                            browsers: env.map( browser => {
                                if(browser.environmentType?.toLowerCase() === "saucelabs" && newMappedOSX[browser.platform] === newMappedOSX[deviceVer.platform] && newMappedOSXVersion[browser.platformVersion] ===  newMappedOSXVersion[deviceVer.platformVersion]){
                                    return{
                                        name: browser.browser,
                                        logoName: browser.browser.toLowerCase(),
                                        versions: env.map(browVer => {
                                            if(browVer.environmentType?.toLowerCase() === "saucelabs" && newMappedOSX[browVer.platform] === newMappedOSX[browser.platform] && newMappedOSXVersion[browVer.platformVersion] === newMappedOSXVersion[browser.platformVersion] && browVer.browser === browser.browser){
                                                return{
                                                    name: browVer.browserVersion,
                                                    id: browVer.id
                                                }
                                            }

                                            return{}
                                        }).filter(version => Boolean(Object.keys(version).length))
                                    }
                                }
                                return {}
                                
                            }).filter((v,i,a) => a.findIndex(v2 => Object.keys(v2).length && Object.keys(v).length && v2.name === v.name ) === i)
                    }
                }
                if(newMappedOSX[deviceVer.platform] === platform && deviceVer.environmentType?.toLowerCase() === "saucelab_devices"){
                    return{
                        name: newMappedOSXVersion[deviceVer.platformVersion],
                        devices: env.map( device => {
                            if(device.environmentType?.toLowerCase() === "saucelab_devices" && newMappedOSX[device.platform] === newMappedOSX[deviceVer.platform] && newMappedOSXVersion[device.platformVersion] ===  newMappedOSXVersion[deviceVer.platformVersion]){
                                return{
                                    name: device.device,
                                    logoName: device.deviceType?.toLowerCase(),
                                    id: device.id,
                                    type: device.deviceType?.toLowerCase()
                                }
                            }
                            return {}
                            
                        }).filter((v,i,a) => a.findIndex(v2 => Object.keys(v2).length && Object.keys(v).length && v2.name === v.name ) === i)
                }
            }
                return {}
            }).filter((v,i,a) => a.findIndex(v2 => Object.keys(v2).length && Object.keys(v).length && v2.name === v.name ) === i)
        })
    })
    this.setState({saucelabs_capabliites: {['capabilities_list']: env, ['capabilities_data_map']: capabilities_data_map}})
}


    setUserDefaultData = (envData, _sauceCredentialsDetails) => {
        let { sauceID } = this.props
        let systemDefaultForSauce = [{
            environmentType: 'Saucelabs',
            id: "Windows 10_10_chrome_92",
            browser: "chrome",
            browserVersion: "92",
            platform: "Windows 10",
            platformVersion: "10",
            appiumVersion: "",
            deviceName: "",
            deviceOrientation: "",
            label:"Windows 10 . Chrome 92.0",
            idEl: null,
            tunnelID: sauceID[0] || '',
        }]
        let systemDefaultForLocal = [{
            environmentType: 'Local',
            browser: 'Chrome (headless)',
            platformVersion: '',
            browserVersion: '',
            platform: 'Linux',
            browserEl: null,
            platformEl: null,
        }]
        const { sauceContent, localContent, deviceSettings } = this.state;
        const sauceDeviceDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'saucelab_devices');
        const sauceDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'saucelabs');
        const localDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'local');
        const ZaleniumDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'zalenium');
        const externalGridDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'remote');
        if ((sauceDetails || sauceDeviceDetails) && _sauceCredentialsDetails?.sauceCredentialId) {
            const sauceUserDefault = sauceDetails?.environment?.platformDetails?.find((elem) => elem.isDefault);
            const sauceDeviceUserDefault = sauceDeviceDetails?.environment?.platformDetails?.find((elem) => elem.isDefault)
            if (sauceUserDefault) {
                let _sauceContent = cloneDeep(sauceContent);
                this.setState({ sauceContent: [{ ...sauceUserDefault, environmentType: 'Saucelabs', idEl: null, tunnelID: sauceID[0] || '' }], 
                                sauceSysDefault: [{ ..._sauceContent[0], ...sauceUserDefault, environmentType: 'Saucelabs' }] ,
                                sauceConfigurationNotFound: true,
                                deviceSettings: [{}], 
                                platformVersion: sauceUserDefault.platformVersion, 
                                tabState: "Saucelabs"
                            })
            }
            else if(sauceDeviceUserDefault){
                let _deviceSettings = JSON.parse(JSON.stringify(deviceSettings))
                _deviceSettings = []
                if(sauceDeviceUserDefault.deviceType?.toLowerCase() === 'emulator'){
                    _deviceSettings[0] = {
                        autoGrantPermissions: false,
                        enableAnimations: false,
                        orientation: 'portrait',
                        options: [{ key: '', value: '' }]
                    }
                }
                else{
                    _deviceSettings[0] = {
                        autoAcceptAlerts: false,
                        autoGrantPermissions: false,
                        enableAnimations: false,
                        orientation: 'portrait',
                        options: [{ key: '', value: '' }]
                    }
                }
                this.setState({
                    sauceContent: [{...sauceDeviceUserDefault, environmentType: 'saucelab_devices',idEl: null, tunnelID: sauceID[0] || ''}],
                    deviceSettings: _deviceSettings,
                    tabState: "Saucelabs",
                    sauceConfigurationNotFound: true,
                })
            } 
            else{
                this.setState({ sauceContent: sauceDetails?.environment?.platformDetails?.length > 0 ? [{...sauceDetails?.environment?.platformDetails[0],idEl: null,tunnelID: sauceID[0] || '',environmentType: 'Saucelabs'}] : systemDefaultForSauce , 
                                sauceSysDefault: [{...sauceDetails?.environment?.platformDetails[0],idEl: null,tunnelID: sauceID[0] || '',environmentType: 'Saucelabs'}], 
                                platformVersion: sauceDetails?.environment?.platformDetails[0].platformVersion,
                                deviceSettings: [{}], 
                                sauceConfigurationNotFound: true,
                                tabState: "Saucelabs"
                            })
            }
        } else {
            this.setState({ sauceConfigurationNotFound: false, sauceContent: [], deviceSettings: [{}], tabState: 'Local' })
        }
        if (localDetails) {
            const localUserDefault = localDetails.environment.platformDetails.find((elem) => elem.isDefault)
            if (localUserDefault) {
                let _localContent = cloneDeep(localContent);
                this.setState({ localContent: [{ ..._localContent[0], ...localUserDefault, browser: `${localUserDefault.browser} (headless)`, environmentType: 'Local'}],
                                localSysDefault: [{ ..._localContent[0], ...localUserDefault, browser: `${localUserDefault.browser} (headless)`, environmentType: 'Local'}],
                                localConfigurationNotFound: true 
                            })
            } else {    
                this.setState({ localContent: localDetails?.environment?.platformDetails?.length > 0 ? [{...localDetails.environment.platformDetails[0],browser: `${localDetails.environment.platformDetails[0].browser} (headless)`, environmentType: 'Local'}] : systemDefaultForLocal,
                                localSysDefault: localDetails?.environment?.platformDetails?.length > 0 ? [{...localDetails.environment.platformDetails[0],browser: `${localDetails.environment.platformDetails[0].browser} (headless)`, environmentType: 'Local'}] : systemDefaultForLocal,
                                localConfigurationNotFound: true
                            })
            }
        } else {
            this.setState({localConfigurationNotFound: false,  localContent: [], tabState: sauceDetails ? 'Saucelabs' : ZaleniumDetails ? 'Local' : 'externalGrid' })
        }
        if (ZaleniumDetails) {
            const zaleniumUserDefault = ZaleniumDetails.environment.platformDetails.find((elem) => elem.isDefault)
            if (zaleniumUserDefault) {
                let _localContent = cloneDeep(localContent);
                this.setState({ localContent: [{ ..._localContent[0], ...zaleniumUserDefault, browser: `${zaleniumUserDefault.browser} (headful)`, environmentType: 'Zalenium' }],
                                localSysDefault: [{ ..._localContent[0], ...zaleniumUserDefault, browser: `${zaleniumUserDefault.browser} (headful)`, environmentType: 'Zalenium' }],
                                localConfigurationNotFound: true })
            } else if(!localDetails?.environment?.platformDetails?.length > 0 && !zaleniumUserDefault){    
                this.setState({ localContent: ZaleniumDetails?.environment?.platformDetails?.length > 0 ? [{...ZaleniumDetails.environment.platformDetails[0],browser: `${ZaleniumDetails.environment.platformDetails[0].browser} (headful)`, environmentType: 'Zalenium'}] : systemDefaultForLocal,
                                localSysDefault: ZaleniumDetails?.environment?.platformDetails?.length > 0 ? [{...ZaleniumDetails.environment.platformDetails[0],browser: `${ZaleniumDetails.environment.platformDetails[0].browser} (headful)`, environmentType: 'Zalenium'}] : systemDefaultForLocal,
                                localConfigurationNotFound: true})
            }
        }
        if (externalGridDetails && externalGridDetails.environment.platformDetails.length) {
                this.setState({ externalGrid: [{...externalGridDetails.environment.platformDetails[0], environmentType: 'remote'}] })
        } else {    
            this.setState({ externalGrid: [] })
        }

    }
    onClickPlatformBrowserContainer = (key, e, callingFrom = 'browser') => {
        const { browserDialog, platformDialog, selectedTab } = this.state;
        const obj = {
            anchorEl: this.makeStateCopy('anchorEl', e && e.currentTarget, key),
            showSelectedExecutionModeDialog: false,
            selectedTab: this.makeStateCopy('selectedTab', this.ModalType === config.executionEngine ? selectedTab[key] : 0, key),
        };

        if (callingFrom === 'Platform') {
            obj.platformDialog = this.makeStateCopy('platformDialog', !platformDialog[key], key);
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
        } else {
            // calling from browser
            obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
            obj.browserDialog = this.makeStateCopy('browserDialog', !browserDialog[key], key);
        }

        this.setState(obj);
    };

    onClickExecutionContainer = (e, key) => {
        this.setState({
            anchorEl: this.makeStateCopy('anchorEl', e && e.currentTarget, key),
            browserDialog: this.makeStateCopy('browserDialog', false, key),
            platformDialog: this.makeStateCopy('platformDialog', false, key),
            showSelectedExecutionModeDialog: !this.state.showSelectedExecutionModeDialog,
        });
    };

    getSelectedBrowserString = (key) => {
        const { selectedBrowsers } = this.state;
        let _newSelectedBrowser = '';
        if (checkIsArray(selectedBrowsers[key])) {
            selectedBrowsers[key].forEach((sb, index) => {
                _newSelectedBrowser = `${_newSelectedBrowser}${index !== 0 ? ', ' : ''}${sb}`;
            });
        } else {
            _newSelectedBrowser = selectedBrowsers[key];
        }
        return _newSelectedBrowser;
    };
    /* Platform / Browser / Execution Actions end */

    /* Not zelinum executionEngine actions start */
    setCurrentTag = () => {
        const { tags } = this.props;
        const { tag } = this.state;

        const tagAlreadyExists = checkArrayLength(tags) && tags.find((t) => t.name.toLowerCase() === tag.toLowerCase());
        if (checkObject(tagAlreadyExists)) {
            this.setState({ currentTag: tagAlreadyExists });
        }
    };

    getMappedSessionIds = (sessionIds) => {
        const mappedIds = {};
        if (checkArrayLength(sessionIds)) {
            sessionIds.forEach((element, index) => {
                mappedIds[`${this.props.suite.testCases[index].testCaseId}`] = element;
            });
        }
        return mappedIds;
    };

    getBrowserDropDownData = (browserDropDownData, _selectedBrowser, _selectedPlatform, selectedPlatforms, selectedBrowsers) => {
        const allBrowsers = JSON.parse(JSON.stringify(browserDropDownData));
        let uniqueBrowsers = [];
        if (checkArrayLength(allBrowsers)) {
            allBrowsers.forEach((browser) => {
                if (uniqueBrowsers.indexOf(browser) === -1) uniqueBrowsers.push(browser);
            });
            uniqueBrowsers = uniqueBrowsers.filter((browser) => {
                return !selectedBrowsers
                    .slice(1, selectedBrowsers.length)
                    .some((b, j) => _selectedPlatform === selectedPlatforms[j + 1] && browser === b);
            });
            if (
                _selectedBrowser &&
                uniqueBrowsers.indexOf(_selectedBrowser) === -1 &&
                checkKeyInObject(this.PlatformBasedBrowsers, _selectedPlatform, 'value', []).includes(_selectedBrowser)
            ) {
                uniqueBrowsers.push(_selectedBrowser);
            }
        }
        return uniqueBrowsers;
    };

    getPlatformDropDownData = () => {
        const { selectedBrowsers, selectedPlatform } = this.state;
        const availablePlatform = [];
        Platform.forEach((platform) => {
            if (
                this.PlatformBasedBrowsers[platform] &&
                checkArrayLength(this.getBrowserDropDownData(this.PlatformBasedBrowsers[platform], '', platform, selectedPlatform, selectedBrowsers))
            ) {
                availablePlatform.push(platform);
            }
        });
        return availablePlatform;
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChangeTag = (tag) => {
        this.setState({ tag });
    };

    handleChangeExecuteBy = (event) => {
        this.setState({ executeBy: event.target.value });
    };

    updateSelectedBrowser = (currentValue, key) => {
        const { selectedBrowsers, selectedPlatform } = this.state;
        const selectedDevice = getSelectedDevice(selectedPlatform[key]);
        let alreadySelectedBrowsers = checkArrayLength(selectedBrowsers[key]) ? [...selectedBrowsers[key]] : [];

        // if selected device is Andriod or iOS only one browser can be selected
        if (selectedDevice === 'Android' || selectedDevice === 'iOS') {
            alreadySelectedBrowsers = [currentValue];
        } else if (checkArrayLength(alreadySelectedBrowsers)) {
            const indexOfCurrentValue = alreadySelectedBrowsers.indexOf(currentValue);
            if (indexOfCurrentValue > -1) {
                alreadySelectedBrowsers.splice(indexOfCurrentValue, 1);
            } else {
                alreadySelectedBrowsers.push(currentValue);
            }
        } else {
            alreadySelectedBrowsers.push(currentValue);
        }
        return alreadySelectedBrowsers;
    };

    handleTabChange = (event, value, key) => {
        this.setState({ selectedTab: this.makeStateCopy('selectedTab', value, key) });
    };

    resetBrowserVersions = (platformNewVal, key) => {
        const { selectedTab } = this.state;
        const browserDropDownData = this.makeStateCopy('browserDropDownData');
        const selectedBrowsers = this.makeStateCopy('selectedBrowsers');
        const browserDetails = Devices[Platform[selectedTab[key]]][platformNewVal];
        if (browserDetails) {
            browserDropDownData[key] = Object.keys(browserDetails);
            BrowserSpecificVersionsArray = Object.values(browserDetails);
            selectedBrowsers[key] = [`${browserDropDownData[key][0]} ${BrowserSpecificVersionsArray[0][0]}`];
            this.setState({
                browserDropDownData,
                selectedBrowsers,
            });
        }
    };

    closePopper = (selectBox, key) => {
        const obj = { anchorEl: this.makeStateCopy('anchorEl', null, key) };
        if (selectBox === 'Platform') {
            obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
        } else if (selectBox === 'Execution') {
            obj.showSelectedExecutionModeDialog = false;
        } else {
            // calling from browser
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
        }
        this.setState(obj);
    };

    generateNewSessionIds = async () => {
        const { aiqExecution, executeBy, suite, type } = this.props;
        this.ws = await WSService.getWebSocketInstance();
        if (aiqExecution && type !== 'suite' && executeBy !== 'tags') {
        	const sessionIds = await WSService.generateSessionIds(1);
            return checkArrayLength(sessionIds) ? sessionIds[0] : '';
        }
        if (checkKeyInObject(suite, 'isAiqExecution') && (type === 'suite' || executeBy === 'tags')) {
            // because suite.isAiqExecution has same as "aiqExecution" in project. Platform takes this value from project when we query
			const res = await WSService.generateSessionIds(suite.testCases.length);
            return res;
        }
        return '';
    };

    handleBrowserChange = (selectedBrowsers, selectedTab, key) => {
        const obj = {
            selectedTab: this.makeStateCopy('selectedTab', selectedTab, key),
            selectedBrowsers: this.makeStateCopy('selectedBrowsers', selectedBrowsers, key),
        };
        const selectedDevice = getSelectedDevice(this.state.selectedPlatform);
        if (this.ModalType === config.executionEngine || selectedDevice === 'Android' || selectedDevice === 'iOS') {
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
            obj.anchorEl = this.makeStateCopy('anchorEl', null, key);
        }
        var emptyBrowser = obj.selectedBrowsers.filter((item) => item.length === 0)
        if (emptyBrowser.length > 0) {
            obj.selectedBrowsers = this.state.selectedBrowsers;
        }
        this.setState(obj);
    };

    handlePlatformChange = (params, key) => {
        const { selectedPlatform, selectedTab, callback = () => { } } = params;
        const obj = {
            platformDialog: this.makeStateCopy('platformDialog', false, key),
            selectedPlatform: this.makeStateCopy('selectedPlatform', selectedPlatform, key),
            selectedTab: this.makeStateCopy('selectedTab', selectedTab, key),
        };
        if (this.ModalType === config.executionEngine) {
            obj.anchorEl = this.makeStateCopy('anchorEl', null, key);
            if (selectedPlatform !== this.state.selectedPlatform[key]) {
                obj.browserDropDownData = this.makeStateCopy('browserDropDownData', this.PlatformBasedBrowsers[selectedPlatform], key);
                obj.selectedBrowsers = this.makeStateCopy(
                    'selectedBrowsers',
                    this.getBrowserDropDownData(
                        this.PlatformBasedBrowsers[selectedPlatform],
                        '',
                        selectedPlatform,
                        obj.selectedPlatform,
                        this.state.selectedBrowsers,
                    )[0] || '',
                    key,
                );
            }
        }
        this.setState(obj, callback);
    };

    handleExecutionMode = (value, key) => {
        this.setState({
            anchorEl: this.makeStateCopy('anchorEl', null, key),
            selectedExecutionMode: value,
            showSelectedExecutionModeDialog: !this.state.showSelectedExecutionModeDialog,
        });
    };

    makeStateCopy = (state, _newVal, key) => {
        let copy = this.state[state];
        if (state !== 'anchorEl') {
            copy = JSON.parse(JSON.stringify(copy));
        }
        if (_newVal !== undefined && (key || key === 0)) {
            copy[key] = _newVal;
        }
        return copy;
    };

    handleExecuteTag = async (params) => {
        const { dataObj, browserDetails, sessionIds } = params;
        const { currentTag, selectedBrowsers } = this.state;
        const { executeTag, getTestSuites, getTestSuitesWithAccount, projectId, user } = this.props;
        const __browserDetails = browserDetails[0];
        const requestData = {
            ...dataObj,
            map: this.getMappedSessionIds(sessionIds),
            tagId: currentTag.tag_id,
            browser: checkArrayLength(checkKeyInObject(__browserDetails, 'browser'))
                ? __browserDetails.browser[0]
                : selectedBrowsers[0].replace('internetExplorer', 'internet explorer'),
            browserVersion: checkKeyInObject(__browserDetails, 'browserVersion', 'value', '0.0'),
            platform: checkKeyInObject(__browserDetails, 'platform', 'value', ''),
            platformVersion: checkKeyInObject(__browserDetails, 'platformVersion', 'value', ''),
        };
        const response = await executeTag(requestData, () => {
            setTimeout(() => {
                let currentPage = '';
                if (checkKeyInObject(window.location, 'pathname')) {
                    currentPage = window.location.pathname === '/testsuites' ? window.location.pathname : window.location.pathname.split('/')[1];
                }
                if (currentPage === 'details') {
                    getTestSuites({ projectId }, user.accountId, true);
                } else if (currentPage === '/testsuites') {
                    getTestSuitesWithAccount();
                }
            }, 2000);
        });
        return response;
    };

    handleExecuteTestSuite = async (params) => {
        const { dataObj, browserDetails, sessionIds, platformbrowserDetail } = params;
        const { executeTestSuite, getExecutionTasks, projectId, saveSessionId, suite } = this.props;
        const mappedIds = this.getMappedSessionIds(sessionIds);
        const __browserDetails = [...browserDetails];

        let newObj = {
            executionMode: dataObj.executionMode,
            executionType: 'smoke',
        }

        __browserDetails.forEach((browserDetail, i) => {
            const selectedDevice = getSelectedDevice(browserDetail.platform);
            if (selectedDevice === 'Android' || selectedDevice === 'iOS') {
                let deviceName = browserDetail.platform;
                if (selectedDevice === 'Android') {
                    deviceName = deviceName.replace('SG ', 'Samsung Galaxy ');
                    deviceName = `${deviceName.includes('Pixel') ? 'Google ' : ''}${deviceName} ${deviceName.includes('Android') ? 'Emulator' : 'GoogleAPI Emulator'
                        }`;
                } else {
                    deviceName = `${deviceName} Simulator`;
                }
                __browserDetails[i].deviceName = deviceName;
                __browserDetails[i].deviceOrientation = 'portrait';
                __browserDetails[i].platformVersion = __browserDetails[i].browserVersion;
                __browserDetails[i].platform = __browserDetails[i].browser;
                __browserDetails[i].browser = selectedDevice === 'iOS' ? 'Safari' : 'Chrome';
                __browserDetails[i].browserVersion = '';
            } else {
                __browserDetails[i].browser = TestScriptUtils.getBrowserValueForSend(__browserDetails[i].browser);
                if (
                    __browserDetails[i].platform &&
                    (__browserDetails[i].platform.includes('macOS') || __browserDetails[i].platform.includes('OS X'))
                ) {
                    __browserDetails[i].platformVersion = MappedOSXVersion[__browserDetails[i].platform];
                    __browserDetails[i].platform = MappedOSX[__browserDetails[i].platform];
                }
                __browserDetails[i].appiumVersion = '';
                __browserDetails[i].deviceName = '';
                __browserDetails[i].deviceOrientation = '';

            }
        });

        const requestData = { ...newObj, platformBrowserDetails: platformbrowserDetail, }; // use browser details in this api as Avishkar said on 17 Feb, 2020
        const response = await executeTestSuite(requestData, suite.testSuiteId, () => {
            setTimeout(() => {
                getExecutionTasks(projectId, 0, -1, 1, false, null, false);
            }, 2000);
            if (checkObject(mappedIds) && saveSessionId) {
                Object.keys(mappedIds).forEach((testCase_Id) => {
                    if (mappedIds.hasOwnProperty(testCase_Id)) {
                        TestCaseUtils.receiveWSEvents(this.ws, {}, { callingFrom: 'ExecuteScriptModal->executeTestSuite' });
                    }
                })
            }
        });
        return response;
    };

    handleExecuteTestScript = async (params) => {
        const { dataObj, browserDetails, sessionIds: sessionId, platformbrowserDetail } = params;
        const { executeTestScript, getExecutionTasks, name, projectId, scriptIds, testCaseId } = this.props;
        const __browserDetails = [...browserDetails];

        delete dataObj.executionMode;
        let obj = {
            ...dataObj,
            scripts: scriptIds,
            testExecutionName: name,
            sessionId,
            projectId,
            testCaseId,
            extraData: {},
        };

        let newObj = {
            scripts: scriptIds,
            testExecutionName: name,
            extraData: {},
            projectId,
            executionType: 'smoke',
        }

        __browserDetails.forEach((browserDetail, i) => {
            const selectedDevice = getSelectedDevice(browserDetail.platform);
            if (selectedDevice === 'Android' || selectedDevice === 'iOS') {
                let deviceName = browserDetail.platform;
                if (selectedDevice === 'Android') {
                    deviceName = deviceName.replace('SG ', 'Samsung Galaxy ');
                    deviceName = `${deviceName.includes('Pixel') ? 'Google ' : ''}${deviceName} ${deviceName.includes('Android') ? 'Emulator' : 'GoogleAPI Emulator'
                        }`;
                } else {
                    deviceName = `${deviceName} Simulator`;
                }
                __browserDetails[i].deviceName = deviceName;
                __browserDetails[i].deviceOrientation = 'portrait';
                __browserDetails[i].platformVersion = __browserDetails[i].browserVersion;
                __browserDetails[i].platform = __browserDetails[i].browser;
                __browserDetails[i].browser = selectedDevice === 'iOS' ? 'Safari' : 'Chrome';
                __browserDetails[i].browserVersion = '';
            } else {
                __browserDetails[i].browser = TestScriptUtils.getBrowserValueForSend(__browserDetails[i].browser);
                if (
                    __browserDetails[i].platform &&
                    (__browserDetails[i].platform.includes('macOS') || __browserDetails[i].platform.includes('OS X'))
                ) {
                    __browserDetails[i].platformVersion = MappedOSXVersion[__browserDetails[i].platform];
                    __browserDetails[i].platform = MappedOSX[__browserDetails[i].platform];
                }
                __browserDetails[i].appiumVersion = '';
                __browserDetails[i].deviceName = '';
                __browserDetails[i].deviceOrientation = '';

            }
        });
        obj = { ...newObj, platformBrowserDetails: platformbrowserDetail };
        const response = await executeTestScript(obj, () => {
            setTimeout(() => {
                getExecutionTasks(projectId, 0, -1, 1, false, null, false);
            }, 2000);
            if (testCaseId && sessionId) {
                //TestCaseUtils.receiveWSEvents(this.ws, {}, { callingFrom: 'ExecuteScriptModal->executeTestScript' });
            }
        });
        return response;
    };

    toggleSwipableTaskPanel = () => {

        this.props.toggleSwipableTaskPanel(true);

        //alert('openSwipableTaskPanel')

    }

    handleExecution = async () => {
        let { currentTag, executeBy, selectedPlatform, selectedBrowsers, selectedExecutionMode, url, use_remote_driver, sauceContent, localContent, externalGrid, deviceSettings, tabState, configurationSetting } = this.state;
        const { type, suite, toggleSnackBar, testCaseId, sauceCredentialDetails } = this.props;
        if (tabState === "externalGrid" && this.validateExternalGridTab()) {
            this.setState({ isFieldEmpty: true })
            return false
        }

        if (configurationSetting.toLowerCase() !== 'lastrunconfiguration') {
            for (let i = 0; i < sauceContent.length; i++) {
                let objToCheck = sauceContent[i]
                let sauceDuplicates = sauceContent.filter(val => (val.environmentType.toLowerCase() === 'saucelabs' && val.browser || val.device) === (objToCheck.environmentType.toLowerCase() === 'saucelabs' && objToCheck.browser || objToCheck.device) && val.platform === objToCheck.platform && (val.environmentType.toLowerCase() === 'saucelabs' && val.browserVersion || val.deviceVersion) === (objToCheck.environmentType.toLowerCase() === 'saucelabs' && objToCheck.browserVersion || objToCheck.deviceVersion))
                if (sauceDuplicates.length > 1) {
                    this.setState({duplicateSauceObjects : objToCheck})
                    return
                } else {
                    this.setState({duplicateSauceObjects : {}})
                }
            }
            for (let i = 0; i < localContent.length; i++) {
                let objToCheck = localContent[i]
                let localDuplicates = localContent.filter(val => val.browser === objToCheck.browser && val.platform === objToCheck.platform)
                if (localDuplicates.length > 1) {
                    this.setState({duplicateLocalObjects : objToCheck})
                    return
                } else {
                    this.setState({duplicateLocalObjects : {}})
                }
            }
            
        }

        let lastRunConfiguration = []
        let isSaucePlatformPresent = true
        let isSaucebrowserPresent = true
        let isLocalplatformPresent = true
        let isLocalbrowserPresent = true
        let isexternalGridbrowserPresent = true
        let isexternalGridPlatformPresent = true
        let isexternalGridPlatformVersionPresent = true

        //Checking Sauce credentials
        if(!Object.keys(sauceCredentialDetails).length){
            lastRunConfiguration = [...localContent, ...externalGrid]
        }
        else{
            deviceSettings = deviceSettings?.map(deviceSetting => (Object.keys(deviceSetting).length ? {...deviceSetting, options: deviceSetting?.options?.map(option => ({key: option?.key?.trim() || '', value: option?.value?.trim() || ''}))} : {}))
            const _sauceContent = [...sauceContent].map((row, ind) => {
                return {
                    ...row,
                    ...deviceSettings[ind],
                }
            });
            lastRunConfiguration = [..._sauceContent, ...localContent, ...externalGrid]
        }

        // Checking invalid values
        lastRunConfiguration = lastRunConfiguration.filter((elem) => {
            if (elem?.environmentType?.toLowerCase() === "saucelabs") {
                // if (!elem.tunnelID || elem.tunnelID.toLowerCase() == 'select id') {
                //     isSauceIDPresent = false;
                //     return false;
                // }
                if (!elem.platform || elem.platform.toLowerCase() === 'select') {
                    isSaucePlatformPresent = false;
                    return false;
                }
                if (!elem.browser || elem.browser.toLowerCase() === 'select') {
                    isSaucebrowserPresent = false;
                    return false;
                }
            }
            if (elem.environmentType.toLowerCase() === "local" || elem.environmentType.toLowerCase() === "zalenium") {
                if (!elem.platform || elem.platform.toLowerCase() === 'select') {
                    isLocalplatformPresent = false;
                    return false;
                }
                if (!elem.browser || elem.browser.toLowerCase() === 'select') {
                    isLocalbrowserPresent = false;
                    return false;
                }
            }
            if (elem.environmentType.toLowerCase() === "remote") {
                if (!elem.platform) {
                    isexternalGridPlatformPresent = false
                    return false
                }
                if (!elem.platformVersion) {
                    isexternalGridPlatformVersionPresent = false
                    return false
                }
                if (!elem.browser) {
                    isexternalGridbrowserPresent = false
                    return false
                }
            }
            return elem;
        })
        if ((!isSaucebrowserPresent || !isSaucePlatformPresent) && sauceContent.length) {
            this.setState({  saucePlatformDisable: !isSaucePlatformPresent, sauceBrowserDisable: !isSaucebrowserPresent })
            return false
        } else {
            this.setState({  saucePlatformDisable: false, sauceBrowserDisable: false })
        }

        if ((!isLocalplatformPresent || !isLocalbrowserPresent) && localContent.length) {
            this.setState({ localBrowserDisable: !isLocalbrowserPresent, localPlatformDisable: !isLocalplatformPresent });
            return false
        } else {
            this.setState({ localBrowserDisable: false, localPlatformDisable: false });
        }

        if ((!isexternalGridPlatformPresent || !isexternalGridPlatformVersionPresent || !isexternalGridbrowserPresent) && externalGrid.length) {
            this.setState({ extPlatform: !isexternalGridPlatformPresent, extPlatformVersion: !isexternalGridPlatformVersionPresent, extBrowser: !isexternalGridbrowserPresent })
            return false
        } else {
            this.setState({ extPlatform: false, extPlatformVersion: false, extBrowser: false, extBrowserVersion: false })
        }

        // If no configuration found
        if (!lastRunConfiguration?.length) {
            this.setState({ noConfigFound: true })
            return null;
        }

        console.log(lastRunConfiguration)
        const sessionIDsPlatform = await WSService.generateSessionIds(lastRunConfiguration.length)
        const platformbrowserDetail = lastRunConfiguration.map((row, index) => {
            let newObj = {
                environmentType: row.environmentType?.toLowerCase(),
                platform: (row?.platform?.toLowerCase().includes('mac') || row?.platform?.toLowerCase().includes('os x')) ? row?.platform : row?.platform?.toLowerCase().replace(/./, c => c.toUpperCase()),
                platformVersion: row.platformVersion,
                browser: row.browser?.replace(/headful|headless/g, "").replace('()', "").trim() || '',
                browserVersion: row.browserVersion || '',
                testcaseSessionIdMap:  { [suite.testSuiteId ? suite.testSuiteId : testCaseId]: sessionIDsPlatform[index] },
                appiumVersion: row.appiumVersion,
                deviceName: row.device || '',
                deviceOrientation: row.orientation || '',
                extraCapabilities: row.options?.filter(option => (option?.key?.length || option?.value?.length)) || [],
                autoAcceptAlerts : row.autoAcceptAlerts || false,
                autoGrantPermission: row.autoGrantPermissions || false,
                enableAnimations: row.enableAnimations || false,
                sauceConnectId: row.sauceConnectId || row.tunnelID,
                deviceType:row.deviceType || '' 

            }
            if(newObj['sauceConnectId']==='None') {
                newObj['sauceConnectId'] =''
            }
            return newObj
        })
        
        localStorage.setItem('lastRunConfiguration', JSON.stringify(lastRunConfiguration.map(row => ({...row, browser: row.browser?.replace(/headful|headless/g, "").replace('()', "").trim().toLowerCase() || '' }))))
        this.props.handleClose();
        if (executeBy === 'tags' && !checkKeyInObject(currentTag, 'tag_id', 'bool')) {
            toggleSnackBar('Tag does not exist', '', false, 2000);
            return false;
        }

        const sessionIds = await this.generateNewSessionIds();
        let isCorrectOrdinal = true;
        const browserDetails = [];
        for (let key = 1; key < selectedPlatform.length; key++) {
            if (selectedBrowsers[key]) {
                const platform = selectedPlatform[key];
                const getDetail = (browserDetail) => {
                    const details = {
                        browser: TestScriptUtils.getBrowserValueForSend(browserDetail[0], this.ModalType),
                        browserVersion: browserDetail[1] ? browserDetail[1] : '0.0',
                        platform: platform === 'Windows 10' ? 'WINDOWS' : platform,
                        platformVersion: platform === 'Windows 10' ? '10' : '',
                    };
                    if (browserDetail[2]) {
                        details.appiumVersion = browserDetail[2];
                    }
                    return details;
                };
                if (checkArrayLength(selectedBrowsers[key])) {
                    selectedBrowsers[key].forEach((brwoser) => {
                        const details = getDetail(brwoser.split(' '));
                        browserDetails.push(details);
                    });
                } else {
                    const details = getDetail(selectedBrowsers[key].split(' '));
                    browserDetails.push(details);
                }
            }
        }
        if (checkArrayLength(browserDetails)) {
            let response = null;
            const dataObj = {
                executionType: 'smoke',
                executionMode: selectedExecutionMode ? selectedExecutionMode.toLowerCase() : '',
                isRemoteDriver: use_remote_driver,
                remoteDriverUrl: use_remote_driver ? url : '',
            };

            if (executeBy === 'tags') {
                response = await this.handleExecuteTag({ dataObj, browserDetails, sessionIds });
            } else if (type === 'suite') {
                // type is from where it is calling
                if (selectedExecutionMode === 'Serial') {
                    isCorrectOrdinal = !suite.testCases.some((__case) => __case.ordinal === 0);
                }
                if (isCorrectOrdinal) {
                    response = await this.handleExecuteTestSuite({ dataObj, browserDetails, sessionIds, platformbrowserDetail });
                }
            } else {
                response = await this.handleExecuteTestScript({ dataObj, browserDetails, sessionIds, platformbrowserDetail });
            }

            if(response && response.type === "CREATE_EXECUTE_TEST_SUITE_SUCCESS") {
                const title = 'Your test suite is running';
                const description = <span>{'Track the progress on the '}<a onClick={this.toggleSwipableTaskPanel} style={{cursor: "pointer",color: 'rgb(255, 255, 255)',fontWeight: '700',textDecoration: 'underline'}}>{'Execution Steps'}</a>{' page.'}</span>

                const alertData = {
                    message:{title,description},
                    duration:12000
                }
                successAlertBar(alertData)

            } else if (type!=='suite' && response) {
                const eventName = SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_EXECUTED;

                let segmentData = {
                    status:'Success',
                    configuration_setting:this.state.configurationSetting,
                    execution_type:dataObj.executionType,
                    is_remote_driver:dataObj.isRemoteDriver,
                    remote_driver_url:dataObj.remoteDriverUrl,
                    platform_browser_details:platformbrowserDetail
                }
                track(eventName,segmentData)

                const title = 'Your test is running';
                const description = <span>{'Track the progress on the '}<a onClick={this.toggleSwipableTaskPanel} style={{cursor: "pointer",color: 'rgb(255, 255, 255)',fontWeight: '700',textDecoration: 'underline'}}>{'Execution Steps'}</a>{' page.'}</span>

                const alertData = {
                    message:{title,description},
                    duration:12000
                }
                successAlertBar(alertData)

            }
            
            else if (!isCorrectOrdinal) {
                toggleSnackBar('Your ordinal is not correct. Please rearrange test cases.', '', false, 2000);
            } else {
                const eventName = SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_EXECUTED;

                let segmentData = {
                    status:'Fail',
                    configuration_setting:this.state.configurationSetting,
                    execution_type:dataObj.executionType,
                    is_remote_driver:dataObj.isRemoteDriver,
                    remote_driver_url:dataObj.remoteDriverUrl,
                    platform_browser_details:platformbrowserDetail
                }
                
                track(eventName,segmentData)



                toggleSnackBar('Execution failed', '', false, 2000);
            }
        }
        return null;
    };
    /* Finalizing Actions end */

    addRow = (i) => {
        const { anchorEl, browserDialog, browserDropDownData, platformDialog, selectedBrowsers, selectedPlatform, selectedTab } = this.state;
        const _selectedPlatform = this.getPlatformDropDownData()[0] || '';

        pushInMidArray(anchorEl, i, null);
        pushInMidArray(browserDialog, i, false);
        pushInMidArray(browserDropDownData, i, this.PlatformBasedBrowsers[_selectedPlatform]);
        pushInMidArray(platformDialog, i, false);
        // Don't change the order of next 2 lines
        pushInMidArray(selectedPlatform, i, _selectedPlatform);
        pushInMidArray(
            selectedBrowsers,
            i,
            this.getBrowserDropDownData(
                this.PlatformBasedBrowsers[_selectedPlatform],
                '',
                _selectedPlatform,
                selectedPlatform,
                this.state.selectedBrowsers,
            )[0] || '',
        );
        // Don't change the order of previous  2 lines
        pushInMidArray(selectedTab, i, 0);
        this.setState({
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedBrowsers,
            selectedPlatform,
            selectedTab,
        });
    };

    deleteRow = (i) => {
        const { anchorEl, browserDialog, browserDropDownData, platformDialog, selectedBrowsers, selectedPlatform, selectedTab } = this.state;
        removeIndexFromArray(anchorEl, i);
        removeIndexFromArray(browserDialog, i);
        removeIndexFromArray(browserDropDownData, i);
        removeIndexFromArray(platformDialog, i);
        removeIndexFromArray(selectedBrowsers, i);
        removeIndexFromArray(selectedPlatform, i);
        removeIndexFromArray(selectedTab, i);
        this.setState({
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedBrowsers,
            selectedPlatform,
            selectedTab,
        });
    };

    handleChangeTab = (state, type) => {
        if (type === 'platform') {
            this.setState({ platformTab: state })
        } else if (type === 'browser') {
            this.setState({ browserTab: state })
        }
    }

    setTabs = (state) => {
        let { sauceContent, localContent } = this.state
        let _sauceContent = sauceContent.map((val) => {
            return {
                ...val,
                idEl: null,
                platformEl: null,
                browserEl: null,
            }
        })
        let _localContent = localContent.map((val) => {
            return {
                ...val,
                platformEl: null,
                browserEl: null,
            }
        })
        this.setState({ tabState: state, sauceContent: _sauceContent, localContent: _localContent, sauceIDDisable: false, noConfigFound: false, overflowStyle:'auto' })
    }

    validateExternalGridTab = () => {
        const { externalGrid } = this.state
        return externalGrid.some((elem) => !elem.platform.length || !elem.platformVersion.length || !elem.browser.length )
    }
    getTabsContent = (state) => {
        let { classes, sauceID, environmentInfo, callingFrom, sauceCredentialDetails } = this.props
        let { saucelabs_capabliites,selectedBrowsersForSauce,deviceSettings, localContent, sauceContent,  externalGrid, tunnelID,  platformLocal, localBrowserValues, configurationSetting, isFieldEmpty, tabState, browserLocal,localSysDefault, sauceIDDisable,  localBrowserDisable, localPlatformDisable, overflowStyle } = this.state
        if (state.toLowerCase() == 'saucelabs') {
            return <PlatformBrowserForSauceExecution
                saucelabs_capabliites={saucelabs_capabliites}
                selectedBrowsersForSauce = {selectedBrowsersForSauce}
                deviceSettings={deviceSettings}
                environmentDetails={environmentInfo}
                callingFrom={callingFrom}
                sauceContent={sauceContent}
                // overflowStyle={overflowStyle}
                sauceIDDisable={sauceIDDisable}
                configurationSetting={configurationSetting}
                sauceTunnelIDs={sauceID}
                tunnelID={tunnelID}
                sauceCredentialDetails = {sauceCredentialDetails}
                classes={classes}
                setState={(obj) => this.setState(obj)}
            />
        } else if (state.toLowerCase() === 'local') {
            return <PlatformBrowserForLocalExecution
                environmentDetails={environmentInfo}
                callingFrom={callingFrom}
                tabState={tabState}
                platformLocal={platformLocal}
                localSysDefault={localSysDefault}
                browserLocal={browserLocal}
                overflowStyle={overflowStyle}
                configurationSetting={configurationSetting}
                localBrowserValues={localBrowserValues}
                localBrowserDisable={localBrowserDisable}
                localPlatformDisable={localPlatformDisable}
                localContent={localContent}
                classes={classes}
                setState={(obj) => this.setState(obj)}
            />
        } else if (state.toLowerCase() === 'externalgrid') {
            return <ExternalGrid
                environmentDetails={environmentInfo}
                externalGrid={externalGrid}
                callingFrom={callingFrom}
                setExternalGrid={(data) => this.setState({ externalGrid: data })}
                configurationSetting={configurationSetting}
                validateExternalGridTab={this.validateExternalGridTab}
                classes={classes}
                type={'executescript'}
                isFieldEmpty={isFieldEmpty}
                setState={(obj) => this.setState(obj)}
            />
        } else {
            return
        }
    }

    handleConfigurationSetting = (e) => {
        let { environmentInfo, sauceID, callingFrom } = this.props
        let { sauceCredentials, deviceSettings } = this.state
        let _deviceSettings = JSON.parse(JSON.stringify(deviceSettings))
        const lastConfigurationData = JSON.parse(localStorage.getItem('lastRunConfiguration'))
        const lastGenerateConfiguration = JSON.parse(localStorage.getItem('lastGenerateConfiguration'))
        let _externalGrid = []
        let _localContent = []
        let _sauceContent = []
        if (lastConfigurationData && e.target.value === 'lastRunConfiguration' && callingFrom.toLowerCase() === 'execute') {
            _externalGrid = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'remote')
            _localContent = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
            _sauceContent = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices')
            _sauceContent = _sauceContent?.map((val, ind) => {
                if(val.deviceType && val.environmentType?.toLowerCase() === 'saucelab_devices'){
                    if(val.deviceType === 'real'){
                        _deviceSettings[ind] = {
                            autoAcceptAlerts: val.autoAcceptAlerts,
                            autoGrantPermissions: val.autoGrantPermissions,
                            enableAnimations: val.enableAnimations,
                            orientation: val.orientation,
                            options: val.options
                        }
                    }
                    else{
                        _deviceSettings[ind] = {
                            autoGrantPermissions: val.autoGrantPermissions,
                            enableAnimations: val.enableAnimations,
                            orientation: val.orientation,
                            options: val.options
                        }
                    }
                    delete val.autoAcceptAlerts
                    delete val.autoGrantPermissions
                    delete val.enableAnimations
                    delete val.orientation
                    delete val.options
                }
                else {
                    _deviceSettings[ind] = {}
                }
                
                return {
                    ...val,
                    tunnelID: sauceID ? (sauceID.includes(val.tunnelID) ? val.tunnelID : sauceID[0]) : ''
                }
            })
            this.setState({
                configurationSetting: e.target.value,
                externalGrid: _externalGrid.length ? _externalGrid : [],
                localContent: _localContent.length ? _localContent : [],
                sauceContent: _sauceContent,
                deviceSettings: _deviceSettings,
                localContentDisable: _localContent.length ? false : true,
                sauceContentDisable: _sauceContent.length && sauceCredentials?.sauceCredentialId ? false : true,
                externalGridDisable: _externalGrid.length ? false : true,
                lastConfigurationDisable: lastConfigurationData.length ? false : true,
                tabState:  _sauceContent?.length && sauceCredentials?.sauceCredentialId ? 'Saucelabs' : _localContent?.length ? 'Local' : _externalGrid?.length ? 'externalGrid' : 'Saucelabs',
                noConfigFound: false,
                localConfigurationNotFound: _localContent.length ? true : false,
                sauceConfigurationNotFound: _sauceContent.length ? true : false,
            })
        } else if (lastGenerateConfiguration && e.target.value === 'lastRunConfiguration' && callingFrom.toLowerCase() === 'generate') {
            _externalGrid = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'remote')
            _localContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
            _sauceContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices')
            _sauceContent = _sauceContent?.map((val, ind) => {
                if(val.deviceType && val.environmentType?.toLowerCase() === 'saucelab_devices'){
                    if(val.deviceType === 'real'){
                        _deviceSettings[ind] = {
                            autoAcceptAlerts: val.autoAcceptAlerts,
                            autoGrantPermissions: val.autoGrantPermission,
                            enableAnimations: val.enableAnimations,
                            orientation: val.orientation,
                            options: val.extraCapabilities
                        }
                    }
                    else{
                        _deviceSettings[ind] = {
                            autoGrantPermissions: val.autoGrantPermission,
                            enableAnimations: val.enableAnimations,
                            orientation: val.orientation,
                            options: val.extraCapabilities
                        }
                    }
                    delete val.autoAcceptAlerts
                    delete val.autoGrantPermission
                    delete val.enableAnimations
                    delete val.orientation
                    delete val.extraCapabilities
                }
                else {
                    _deviceSettings[ind] = {}
                }
                return {
                    ...val,
                    tunnelID: sauceID ? (sauceID.includes(val.tunnelID) ? val.tunnelID : sauceID[0]) : ''
                }
            })
            this.setState({
                configurationSetting: e.target.value,
                externalGrid: _externalGrid.length ? _externalGrid : [],
                localContent: _localContent.length ? _localContent : [],
                sauceContent: _sauceContent,
                deviceSettings: _deviceSettings,
                localContentDisable: _localContent.length ? false : true,
                sauceContentDisable: _sauceContent.length && sauceCredentials?.sauceCredentialId ? false : true,
                externalGridDisable: _externalGrid.length ? false : true,
                lastConfigurationDisable: lastGenerateConfiguration.length ? false : true,
                tabState: lastGenerateConfiguration[0].environmentType.toLowerCase() === 'zalenium' ? 'Local' : lastGenerateConfiguration[0].environmentType === "remote" ? 'externalGrid' : lastGenerateConfiguration[0].environmentType.toLowerCase() === 'saucelab_devices' ? 'Saucelabs' : lastGenerateConfiguration[0].environmentType,
                noConfigFound: false,
                localConfigurationNotFound: _localContent.length ? true : false,
                sauceConfigurationNotFound: _sauceContent.length ? true : false,
            })
        } else {
            if (environmentInfo && environmentInfo?.environments?.length) {
                let saucelab = [];
                let saucelabsDevices = [];
                if(environmentInfo?.environments?.some(env => env.environmentType?.toLowerCase() === "saucelabs")){
                    saucelab = environmentInfo?.environments?.find(env => env.environmentType?.toLowerCase() === "saucelabs").environment.platformDetails?.map(elem => {
                        return{
                           ...elem,
                           environmentType: "Saucelabs"
                        }
                    })
                }
                if(environmentInfo?.environments?.some(env => env.environmentType?.toLowerCase() === "saucelab_devices")){
                    saucelabsDevices = environmentInfo?.environments?.find(env => env.environmentType?.toLowerCase() === "saucelab_devices").environment.platformDetails?.map(elem => {
                        return{
                           ...elem,
                           environmentType: "saucelab_devices"
                        }
                    })
                }
                if((saucelab.length || saucelabsDevices.length) && sauceCredentials?.sauceCredentialId){
                    this.constructDataForSauceMapping([...saucelab, ...saucelabsDevices])
                }
                this.setUserDefaultData(environmentInfo, sauceCredentials)
                this.setState({
                    configurationSetting: e.target.value,
                    localContentDisable: false,
                    sauceContentDisable: false,
                    externalGridDisable: false,
                })

            } else {
                this.setState({
                    localConfigurationNotFound: false,
                    sauceConfigurationNotFound: false,
                    configurationSetting: e.target.value,
                    localContentDisable: false,
                    sauceContentDisable: false,
                    externalGridDisable: false,
                })
            }

        }

    }

    reviceWSEvents = (testCase = null) => {
        const actions = {
            UpdateScriptMessageJsonFromCaseTable: ({ sendFifteen, sendEighteen }) => {
                if (checkObject(testCase)) {
                    this.UpdateScriptMessageJson(testCase, () => { }, sendFifteen, sendEighteen);
                }
            },
        };

        const data = {
            callingFrom: 'CaseTable',
            paramTestCaseId: testCase && testCase.testCaseId,
        };
        // Invoking web socket receiver
        TestCaseUtils.receiveWSEvents(this.ws, actions, data);
    };

    UpdateScriptMessageJson = async (testCase, onComplete = () => { }, sendFifteen = true, sendEighteen = true) => {
        const {
            project: { cacheXpaths, projectId },
            failedStepsData,
            debugStepsData,
            toggleMsgType15Flag,
            toggleMsgType18Flag,
            saveSessionId,
            clearWsData,
            removeMsgType2Data,
            handleClose,
            testCaseId,
            creationMode,
            debugPointList,
            sauceCredentialDetails,
            isGenFromOriginalSteps,
            sendOriginalSteps
        } = this.props
        const { sauceContent, localContent, externalGrid, tabState, deviceSettings } = this.state
        const paramTestCaseId = testCase.testCaseId;
        const Steps = testCase.recoverTestSteps ? [...testCase.recoverTestSteps] : [];
        const filteredTestSteps = [];
        const stepsToSend = [];

        let lastRunConfiguration = {}
        console.log("Websocket Request Called And Waiting For Response");
        const sessionIDsPlatform = await WSService.generateSessionIds(1);
        console.log("Websocket Response:::::>> ", sessionIDsPlatform);
        if(tabState.toLowerCase() == 'saucelabs' && sauceContent.length && Object.keys(sauceCredentialDetails).length > 0) {

            // if (sauceContent[0] && !sauceContent[0].tunnelID) {
            //     //this.setState({sauceIDDisable:true})
            //     //return false;
            // } else {
            //     this.setState({sauceIDDisable:false})
            // }
            if (sauceContent[0] && !sauceContent[0].platform) {
                this.setState({ saucePlatformDisable: true })
                return false;
            } else {
                this.setState({ saucePlatformDisable: false })
            }
            if (sauceContent[0] && ((sauceContent[0].environmentType.toLowerCase() === 'saucelabs' && !sauceContent[0].browser) || (sauceContent[0].environmentType.toLowerCase() === 'saucelab_devices' && !sauceContent[0]?.device))) {
                this.setState({ sauceBrowserDisable: true })
                return false;
            } else {
                this.setState({ sauceBrowserDisable: false })
            }
            lastRunConfiguration = {
                environmentType: sauceContent[0]?.environmentType?.toLowerCase(),
                platform: (sauceContent[0]?.platform?.toLowerCase().includes('mac') || sauceContent[0]?.platform?.toLowerCase().includes('os x')) ? sauceContent[0]?.platform.trim() : sauceContent[0]?.platform?.toLowerCase().replace(/./, c => c.toUpperCase()).trim(),
                platformVersion: sauceContent[0]?.platformVersion,
                browser: sauceContent[0]?.browser?.trim() || '',
                browserVersion: sauceContent[0]?.browserVersion || '',
                testcaseSessionIdMap: { [testCaseId]: sessionIDsPlatform[0] },
                appiumVersion: sauceContent[0]?.appiumVersion,
                deviceName: sauceContent[0]?.device || '',
                deviceOrientation: deviceSettings[0]?.orientation || '',
                extraCapabilities: deviceSettings[0]?.options?.filter(option => (option?.key?.length || option?.value?.length)).map(option => ({key: option?.key?.trim(), value: option?.value?.trim()})) || [],
                autoAcceptAlerts: deviceSettings[0]?.autoAcceptAlerts || false,
                autoGrantPermission: deviceSettings[0]?.autoGrantPermissions || false,
                enableAnimations: deviceSettings[0]?.enableAnimations || false,
                sauceConnectId: sauceContent[0]?.sauceConnectId || sauceContent[0].tunnelID,
                deviceType:sauceContent[0].deviceType || ''
            }
            if(lastRunConfiguration['sauceConnectId'] === 'None') {
                lastRunConfiguration['sauceConnectId'] = ''
            }

        } else if (tabState.toLowerCase() === 'local' && localContent.length) {
            if (localContent[0] && !localContent[0].platform) {
                this.setState({ localPlatformDisable: true })
                return false;
            } else {
                this.setState({ localPlatformDisable: false })
            }
            if (localContent[0] && !localContent[0]?.browser) {
                this.setState({ localBrowserDisable: true })
                return false;
            } else {
                this.setState({ localBrowserDisable: false })
            }
            lastRunConfiguration = {
                environmentType: localContent[0]?.environmentType.toLowerCase(),
                platform: (localContent[0]?.platform?.toLowerCase().includes('mac') || localContent[0]?.platform?.toLowerCase().includes('os x')) ? localContent[0]?.platform.trim() : localContent[0]?.platform?.toLowerCase().replace(/./, c => c.toUpperCase()).trim(),
                platformVersion: localContent[0]?.platformVersion,
                browser: localContent[0]?.browser.replace(/headful|headless/g, "").replace('()', "").trim().toLowerCase(),
                browserVersion: localContent[0]?.browserVersion,
                map: { [testCaseId]: sessionIDsPlatform[0] },
                appiumVersion: localContent[0]?.appiumVersion,
                deviceName: localContent[0]?.deviceName?.toLowerCase(),
                deviceOrientation: localContent[0]?.deviceOrientation?.toLowerCase(),
            }
        } else if (tabState.toLowerCase() === 'externalgrid' && externalGrid.length) {
            if (externalGrid[0] && !externalGrid[0]?.platform) {
                this.setState({ extPlatform: true })
                return false
            } else {
                this.setState({ extPlatform: false })
            }
            if (externalGrid[0] && !externalGrid[0].platformVersion) {
                this.setState({ extPlatformVersion: true, isFieldEmpty: true })
                return false
            } else {
                this.setState({ extPlatformVersion: false })
            }
            if (externalGrid[0] && !externalGrid[0].browser) {
                this.setState({ extBrowser: true, isFieldEmpty: true })
                return false
            } else {
                this.setState({ extBrowser: false })
            }
            
            lastRunConfiguration = {
                environmentType: externalGrid[0]?.environmentType.toLowerCase(),
                platform: (externalGrid[0]?.platform?.toLowerCase().includes('mac') || externalGrid[0]?.platform?.toLowerCase().includes('os x')) ? externalGrid[0]?.platform.trim() : externalGrid[0]?.platform?.toLowerCase().replace(/./, c => c.toUpperCase()).trim(),
                platformVersion: externalGrid[0]?.platformVersion,
                browser: externalGrid[0]?.browser?.trim().toLowerCase(),
                browserVersion: externalGrid[0]?.browserVersion,
                map: { [testCaseId]: sessionIDsPlatform[0] },
                appiumVersion: externalGrid[0]?.appiumVersion,
                deviceName: externalGrid[0]?.deviceName?.toLowerCase(),
                deviceOrientation: externalGrid[0]?.deviceOrientation?.toLowerCase(),
            }
        } else {
            this.setState({ noConfigFound: true })
            return false
        }

        const _lastRunConfiguration = lastRunConfiguration.environmentType === 'saucelabs' ? {...lastRunConfiguration, tunnelID: sauceContent[0]?.tunnelID, label: sauceContent[0].label, id: sauceContent[0].id} : lastRunConfiguration.environmentType === 'saucelab_devices' ? {...lastRunConfiguration, label: sauceContent[0].label, id: sauceContent[0].id, deviceOrientation: '', deviceName: '', device: sauceContent[0]?.device, orientation: deviceSettings[0].orientation, tunnelID: sauceContent[0]?.tunnelID, extraCapabilities: deviceSettings[0]?.options?.map(option => ({key: option?.key?.trim(), value: option?.value?.trim()}))} : lastRunConfiguration
        localStorage.setItem('lastGenerateConfiguration', JSON.stringify([_lastRunConfiguration]))

        if(isGenFromOriginalSteps){
            sendOriginalSteps(lastRunConfiguration)
            handleClose();
            return
        }

        Steps.forEach((step) => {
            filteredTestSteps.push({
                ...step,
                subInstructions: [],
            });
        });

        filteredTestSteps.forEach((step) => {
            if (step.instr) {
                stepsToSend.push({
                    data: step.data,
                    instr: step.instr,
                    expectedResults: step.expectedResults,
                    columnName: step.columnName,
                    xpath: cacheXpaths ? step.xpath || '' : '',
                    stepId: `${step.stepId || ''}`,
                    ...step
                });
            }
        });
        const isMsgType13Received = checkKeyInObject(failedStepsData, paramTestCaseId) && failedStepsData[paramTestCaseId].instrNum;
        const isMsgType16Received = checkKeyInObject(debugStepsData, paramTestCaseId) && debugStepsData[paramTestCaseId].instrNum;
        if (isMsgType13Received && sendFifteen) {
            console.info('myMessage object sending in msgType: 15', failedStepsData[paramTestCaseId].sessionId);
            WSService.sendMessage(
                JSON.stringify({
                    msgType: 15,
                    sessionId: failedStepsData[paramTestCaseId].sessionId,
                    accountId: WSService.getAccountId(),
                }),
            );
            toggleMsgType15Flag(paramTestCaseId, [], true);
        } else if (isMsgType16Received && sendEighteen) {
            console.info('myMessage object sending in msgType: 18', debugStepsData[paramTestCaseId].sessionId);
            WSService.sendMessage(
                JSON.stringify({
                    msgType: 18,
                    sessionId: debugStepsData[paramTestCaseId].sessionId,
                    accountId: WSService.getAccountId(),
                }),
            );
            toggleMsgType18Flag(paramTestCaseId, [], true);
        } else {
            const isTestCaseAlreadyRunning = await TestCaseMiddleware.getTestCaseGenerationStatus(paramTestCaseId);
            if (!isTestCaseAlreadyRunning) {
                const _projectId = Number(projectId);
                const _testcaseId = Number(paramTestCaseId);
                const newSessionId = sessionIDsPlatform[0];
                // console.info('myMessage object sending in msgType: 2', myMessage);
                saveSessionId(newSessionId, `${paramTestCaseId}`, 'CaseTable');
                try {
                    isWaitingForMsgTypeTwoResponse = true;

                    let consoleMessage = `DATA SENT through retry API request with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                    let color = 'DodgerBlue';
                    // eslint-disable-next-line no-console
                    console.log(`%c${consoleMessage}`, `color:${color};`, [...stepsToSend]);

                    const res = await TestCaseUtils.updateStepsRequestPayload(_projectId, _testcaseId, stepsToSend, newSessionId, creationMode === true ? [] : debugPointList[testCaseId], creationMode, lastRunConfiguration );

                    const eventName = SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_GENERATED;
                    const segmentData = {
                        status:'Success',
                        project_id : _projectId,
                        testcase_id : _testcaseId,
                        testcase_name:testCase.testCaseName,
                    }

                    track(eventName,segmentData);

                    if (checkKeyInObject(res, 'type') && res.type === 'UPDATE_STEPS_SUCCESS') {
                        consoleMessage = `RECEIVED retry API success respons with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                        color = 'Green';
                        // eslint-disable-next-line no-console
                        console.log(`%c${consoleMessage}`, `color:${color};`);
                    } else {
                        clearWsData(paramTestCaseId, 'runApiFailed');
                        consoleMessage = `RECEIVED retry API failure respons with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                        color = 'red';
                        // eslint-disable-next-line no-console
                        console.log(`%c${consoleMessage}`, `color:${color};`);
                        removeMsgType2Data([`${paramTestCaseId}`], 'CaseTable');
                    }
                    isWaitingForMsgTypeTwoResponse = false;
                } catch (err) {
                    // eslint-disable-next-line no-console

                    const eventName = SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_GENERATED;
                    const segmentData = {
                        status:'Fail',
                        configuration_setting:this.state.configurationSetting,
                        project_id : _projectId,
                        testcase_id : _testcaseId,
                        testcase_name:testCase.testCaseName,
                        platform_browser_details : lastRunConfiguration
                    }
                    
                    track(eventName,segmentData);

                    console.log(err, 'ERROR');
                }
            } else {

                warningAlertBar({
                    message:{
                        title:'Script is being generated',
                        description:'Once the script generation is complete, you can run your test.'
                    },
                    duration:20000,

                })
                //showSnackBar('Generation/Edit in progress. Some features will not be accessible.', '', false, 8000, true);
            }
        }
        this.reviceWSEvents(testCase);
        onComplete();
    };

    handleGenerateCase = async () => {
        let { testCase, handleClose, setShowLoaderForGenerate, setIsGenerateBtnClicked } = this.props
        handleClose();
        setIsGenerateBtnClicked({[testCase.testCaseId] : true})
        setShowLoaderForGenerate({[testCase.testCaseId] : true})
        const _steps = await getStepsData(testCase.testCaseId);
        if (
            (checkKeyInObject(_steps, 'testSteps') && checkArrayLength(_steps.testSteps)) ||
            (checkKeyInObject(_steps, 'recoverTestSteps') && checkArrayLength(_steps.recoverTestSteps))
            ) {
                testCase = { ...testCase, ..._steps };
                this.UpdateScriptMessageJson(testCase);
        }
    }

    // getIconToDisplay = (platform) => {
    //     const { classes } = this.props
    //     if (platform.toLowerCase().includes('window')) {
    //         return <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faWindows} aria-label="windows" id="windows" />
    //     } else if (platform.toLowerCase().includes('linux')) {
    //         return <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faLinux} aria-label="linux" id="linux" />
    //     } else if (platform.toLowerCase().includes('mac')) {
    //         return <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faApple} aria-label="macos" id="macos" />
    //     } else if (platform.toLowerCase().includes('ios')) {
    //         return <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faApple} aria-label="ios" id="ios" />
    //     } else if (platform.toLowerCase().includes('android')) {
    //         return <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} style={{ marginTop: 0 }} icon={faAndroid} aria-label="android" id="android" />
    //     } else {
    //         return
    //     }
    // }

    render() {
        const { classes, handleClose, modalState, name, tags, type,  callingFrom, environmentInfo } = this.props;
        const {
            anchorEl,
            executeBy,
            openModal,
            selectedExecutionMode,
            showSelectedExecutionModeDialog,
            tag,
            selectedTab,
            tabState,
            configurationSetting,
            localContentDisable,
            sauceContentDisable,
            externalGridDisable,
            lastConfigurationDisable,
            sauceIDDisable,
            sauceBrowserDisable,
            saucePlatformDisable,
            localBrowserDisable,
            localPlatformDisable,
            extPlatform,
            extBrowser,
            extPlatformVersion,
            noConfigFound,
            sauceContent,
            localContent,
            externalGrid,
            localConfigurationNotFound,
            sauceConfigurationNotFound,
            duplicateSauceObjects,
            duplicateLocalObjects,
            sauceCredentials,
            SauceCredentialLoad,
            localSysDefault,
        } = this.state;

        let errorTestCases = [];
        if (type === 'suite' && executeBy !== 'tags') {
            errorTestCases = checkArrayLength(this.props.suite.testCases)
                ? this.props.suite.testCases.filter((_case) => !_case.isScriptAvailable)
                : [];
        }
        

        const PlatformBrowserSelectionContainerProps = (key) => {
            return {
                anchorEl: anchorEl[key],
                handleTabChange: (...args) => {
                    this.handleTabChange(...args, key);
                },
                closePopper: (...args) => {
                    this.closePopper(...args, key);
                },
                onClickPlatformBrowserContainer: (...args) => {
                    this.onClickPlatformBrowserContainer(key, ...args);
                },
                type,
                modalType: this.ModalType,
                selectedTab: selectedTab[key],
            };
        };

        return (
           <span>
            {SauceCredentialLoad && (<Dialog
                disableRestoreFocus
                open={modalState}
                onClose={handleClose}
                classes={{ paper: classes.dialogContainer }}
                aria-labelledby="simple-dialog-title"
                className={classes.dialogRoot}
            >
                <DialogTop title={`${callingFrom.toLowerCase() === 'generate' ? 'Generate Test' : 'Execute Test'}`} suiteName={name} closeIconAction={handleClose} />
                <form autoComplete="off" className={classes.formContainer} style={type !== 'suite' ? {height: 330} : {}}>
                    <DialogContent className={classes.dialogContent} style={{ maxHeight: 445, overflowY: 'unset' }}>
                        <ExecuteScriptConfigurationDetails 
                            configurationSetting = {configurationSetting}
                            lastConfigurationDisable = {lastConfigurationDisable}
                            sauceContentDisable = {sauceContentDisable}
                            localContentDisable = {localContentDisable}
                            externalGridDisable = {externalGridDisable}
                            classes = {classes}
                            handleChangeTab = {this.handleChangeTab}
                            _setState = {(obj) => this.setState(obj)}
                            getTabsContent = {this.getTabsContent}
                            calledFrom = 'executionScriptModal'
                            environmentInfo = {environmentInfo}
                            localContent = {localContent}
                            sauceContent = {sauceContent}
                            externalGrid = {externalGrid}
                            localSysDefault = {localSysDefault}
                            tabState = {tabState}
                            localConfigurationNotFound = {localConfigurationNotFound}
                            sauceConfigurationNotFound = {sauceConfigurationNotFound}
                            sauceCredentials = {sauceCredentials}
                            SauceCredentialLoad = {SauceCredentialLoad}
                            setTabs = {this.setTabs}
                            handleConfigurationSetting = {this.handleConfigurationSetting}
                        />
                         {type === 'suite' ? (
                            <div className={classes.executionTextFieldContainer}>
                                <PlatformBrowserSelectionContainer
                                    {...PlatformBrowserSelectionContainerProps(0)}
                                    dropDownData={ExecutionModes}
                                    handleExecutionMode={(...args) => {
                                        this.handleExecutionMode(...args, 0);
                                    }}
                                    isOpenDialog={showSelectedExecutionModeDialog}
                                    onClickExecutionContainer={(...args) => {
                                        this.onClickExecutionContainer(...args, 0);
                                    }}
                                    title="Execution"
                                    selectedDropDownValue={selectedExecutionMode}
                                />
                            </div>
                        ) : null}
                        {openModal && errorTestCases.length && (
                            <DeleteAlertModal
                                open={openModal}
                                handleClose={() => {
                                    this.setState({ openModal: false });
                                }}
                                modalfor="executeScript"
                                msg="Please generate following test cases first."
                                list={errorTestCases.map((_case) => _case.testCaseName)}
                                title={errorTestCases.length > 1 ? 'TestCases name' : 'TestCase name'}
                                cancelBtnText="Close"
                            />
                        )}
                        <ExecutionErrorDetails 
                            tabState = {tabState}
                            sauceIDDisable = {sauceIDDisable}
                            saucePlatformDisable = {saucePlatformDisable}
                            sauceBrowserDisable = {sauceBrowserDisable}
                            localBrowserDisable = {localBrowserDisable}
                            localPlatformDisable = {localPlatformDisable}
                            extPlatform = {extPlatform}
                            extBrowser = {extBrowser}
                            extPlatformVersion = {extPlatformVersion}
                            calledFrom = "executionScriptModal"
                            noConfigFound = {noConfigFound}
                            duplicateSauceObjects = {duplicateSauceObjects}
                            duplicateLocalObjects = {duplicateLocalObjects}
                            sauceCredentials = {sauceCredentials}
                            configurationSetting = {configurationSetting}
                            SauceCredentialLoad = {SauceCredentialLoad}
                        />
                    </DialogContent>
                    <OverviewPanel
                        classes = {classes}
                        sauceContent = {sauceContent}
                        sauceCredentials = {sauceCredentials}
                        localContent = {localContent}
                        externalGrid = {externalGrid}
                        errorTestCases = {errorTestCases}
                        handleExecution = {(e) => this.handleExecution()}
                        handleGenerateCase = {(e) =>this.handleGenerateCase()}
                        callingFrom = {callingFrom}
                        _setState = {(obj) => this.setState(obj)}
                        tabState = {tabState}
                        configurationSetting = {configurationSetting}
                        sauceConfigurationNotFound = {sauceConfigurationNotFound}
                        localConfigurationNotFound = {localConfigurationNotFound}
                        tag = {tag}
                        type = {type}
                        executeBy = {executeBy}
                        handleClose = {handleClose}
                    />
                </form> 
                </Dialog>) }
                </span>
        
        );
    }
}

ExecuteScriptModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    modalState: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    // projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // scriptIds: PropTypes.array,
    suite: PropTypes.shape({}),
    tags: PropTypes.array,
    // functions
    executeTestScript: PropTypes.func.isRequired,
    executeTestSuite: PropTypes.func.isRequired,
    executeTag: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    toggleSnackBar: PropTypes.func.isRequired,
};

ExecuteScriptModal.defaultProps = {
    scriptIds: [],
    tags: [],
    suite: {},
};

const mapStateToProps = (state, props) => {
    return {
        // auth Reducer
        user: state.authReducer.user,
        // tag reducer
        tags: state.tagReducer.tags,
        prefences: state.accountReducer.prefences,
        environmentInfo: state.projectReducer.environmentInfo,
        environmentError: state.projectReducer.environmentError,
        sauceID: state.projectReducer.sauceID,
        project: state.projectReducer.selectedProject,
        failedStepsData: state.projectReducer.failedStepsData[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
        debugStepsData: state.projectReducer.debugStepsData[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
        debugPointList: state.projectsReducer.debugPointList,
        creationMode: state.selectedTestCaseReducer.creationMode,
        //sauce details 
        sauceCredentialDetails:state.accountReducer.sauceDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Execution Action
        executeTag: (...args) => dispatch(ExecutionActions.executeTag(...args)),
        //  Modal Action
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // Project Action
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        executeTestScript: (...args) => dispatch(ProjectActions.executeTestScript(...args)),
        toggleMsgType15Flag: (...args) => dispatch(ProjectActions.toggleMsgType15Flag(...args)),
        toggleMsgType18Flag: (...args) => dispatch(ProjectActions.toggleMsgType18Flag(...args)),
        getEnvironmentType: () => dispatch(ProjectActions.getEnvironmentType()),
        saveSessionId: (...args) => dispatch(ProjectActions.saveSessionId(...args)),
        clearWsData: (...args) => dispatch(ProjectActions.clearWsData(...args)),
        removeMsgType2Data: (...args) => dispatch(ProjectActions.removeMsgType2Data(...args)),
        // Tag Action
        getTags: (...args) => dispatch(TagActions.getTags(...args)),
        // Test suite Action
        getTestSuites: (...args) => dispatch(TestSuiteActions.getTestSuites(...args)),
        getTestSuitesWithAccount: (...args) => dispatch(TestSuiteActions.getTestSuitesWithAccount(...args)),
        executeTestSuite: (...args) => dispatch(TestSuiteActions.executeTestSuite(...args)),
        getTunnelIDForSauceLabs: () => dispatch(ProjectActions.getTunnelIDForSauceLabs()),
        setIsGenerateBtnClicked: (data) => dispatch(ProjectActions.setIsGenerateBtnClicked(data)),
        setShowLoaderForGenerate: (data) => dispatch(ProjectActions.setShowLoaderForGenerate(data)),
        //sauce details 
        getSauceDetails: () => dispatch(AccountActions.getSauceDetails()),
        toggleSwipableTaskPanel: (flag) => dispatch(GeneralActions.toggleSwipableTaskPanel(flag))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecuteScriptModal));